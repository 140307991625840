import React, { Fragment, useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Circle, useMap } from "react-leaflet";
import L from "leaflet";
import Intro from "./Intro";
import Timer from "./Timer";
import QuizCompontent from "./QuizCompontent";
import Questions from "./Questions";
import Backpack from "./Backpack";
import Notification from "./Notification";
import Correct from "./Correct";
import Lose from "./Lose";
import Winner from "./Winner";
import TimeLose from "./TimeLose";
import AfterQuestionModal from "./AfterQuestionModal";

const customIcon = new L.Icon({
  iconUrl: "/assets/question-mark.png",
  iconSize: [15, 15],
  iconAnchor: [7, 7],
});

const answeredIcon = new L.Icon({
  iconUrl: "/assets/home-button.png",
  iconSize: [15, 15],
  iconAnchor: [7, 7],
});

const userIcon = new L.Icon({
  iconUrl: "/assets/marker-icon.png",
  iconSize: [20, 20],
  iconAnchor: [10, 10],
});

function getDistance(lat1, lon1, lat2, lon2) {
  const R = 6371e3;
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

const MapComponent = ({ code, location }) => {
  const [intro, setIntro] = useState(false);
  const [result, setResult] = useState(null);
  const [isNewGame, setIsNewGame] = useState(true);
  const [position, setPosition] = useState(null);
  const [dateUsed, setDateUsed] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [questions, setQuestions] = useState([]);
  const [debugIndex, setDebugIndex] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [secretWord, setSecretWord] = useState("");
  const [timerRanOut, setTimerRanOut] = useState(false);
  const [finalPart, setFinalPart] = useState(false);
  const [endScreen, setEndScreen] = useState(false);
  const [showWinner, setShowWinner] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [completionTime, setCompletionTime] = useState(null);
  const [mapInteractable, setMapInteractable] = useState(true);
  const [notificationText, setNotificationText] = useState("");
  const [backpackVisible, setBackpackVisible] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [secretWordDisplay, setSecretWordDisplay] = useState("");
  const [showFinalDestination, setShowFinalDestination] = useState(false);
  const [afterQuestionModalText, setAfterQuestionModalText] = useState("");

  const getFinalDestination = (location) => {
    if (location === "gamlebyen" || location === "gamlebyen_eng") {
      return [59.200852, 10.950995];
    } else if (location === "hamar" || location === "hamar_eng") {
      return [60.79346, 11.07021];
    }
    // console.log("The location of the game is:", location);
    // Default case, you might want to handle this differently
    return [0, 0];
  };

  const finalDestination = getFinalDestination(location.toLowerCase());
  // console.log("The finale place is:", finalDestination);

  const debugBtnClick = () => {
    // let locations = [[59.202980, 10.957410], [59.2093, 10.9424]];
    let locations = [
      [60.79312, 11.03991],
      [60.792706, 11.039113],
      [60.79244, 11.03716],
      [60.79242, 11.03665],
      [60.79199, 11.03769],
      [60.79204, 11.03757],
      [60.79188, 11.042],
      [60.79279, 11.04919],
      [60.79373, 11.06186],
      [60.792807, 11.065306],
      [60.79346, 11.06683],
      [60.79297, 11.07022],
      [60.78886, 11.06802],
      [60.79346, 11.07021],
    ];
    setPosition(locations[debugIndex]);
    setDebugIndex((prevIndex) => (prevIndex + 1) % locations.length);
  };

  const fetchSecretWord = async () => {
    try {
      const response = await fetch(
        `https://escapecitygames.pellenilsen.no/api/get-secret/${location}`
      );
      const data = await response.json();
      if (response.ok) {
        setSecretWord(data.secret);
        setSecretWordDisplay("_".repeat(data.secret.length));
      } else {
        console.error("Failed to fetch secret word:", data.message);
      }
    } catch (error) {
      console.error("Error fetching secret word:", error);
    }
  };

  const getQuestions = async () => {
    if (questions.length > 0) return;
    try {
      const response = await fetch(
        `https://escapecitygames.pellenilsen.no/api/questions/${location}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      if (response.ok) {
        const questionsWithVisibility = data.data.map((question, index) => ({
          ...question,
          visible: index === 0,
          answered: false,
        }));
        console.log("Questions:", data);
        setQuestions(questionsWithVisibility);
      } else {
        console.error("Failed to retrieve questions:", data.message);
      }
    } catch (error) {
      console.error("Error retrieving questions:", error);
    }

    fetchSecretWord();
  };

  const checkProgress = async () => {
    fetchSecretWord();

    try {
      const response = await fetch(
        `https://escapecitygames.pellenilsen.no/api/get-progress/${code}`
      );
      const data = await response.json();
      if (response.ok && data.success) {
        if (data.data.progressSequence && data.data.progressSecretWord) {
          console.log("Resuming game with existing progress");
          // setSecretWord(data.data.progressSecretWord);
          setSecretWordDisplay(data.data.progressSecretWord);
          console.log("Secret word: " + data.data.progressSecretWord);
          console.log("Progress: " + data.data.progressSequence);

          // Fetch questions
          const questionsResponse = await fetch(
            `https://escapecitygames.pellenilsen.no/api/questions/${location}`
          );
          const questionsData = await questionsResponse.json();
          if (questionsResponse.ok) {
            const progressSequence = data.data.progressSequence;
            const nextVisibleIndex = progressSequence.length;

            const updatedQuestions = questionsData.data.map(
              (question, index) => {
                const answered = index < progressSequence.length;
                const correct = progressSequence[index] === "1";
                const isNextQuestion = index === nextVisibleIndex;
                return {
                  ...question,
                  answered,
                  correct,
                  visible: isNextQuestion || answered,
                };
              }
            );
            setQuestions(updatedQuestions);
            console.log("Updated questions:", updatedQuestions);
          } else {
            console.log("Starting a new game");
            getQuestions(); // Start a new game if no progress is found
          }
        }
      } else {
        console.error("Failed to fetch progress:", data.message);
        getQuestions(); // Start a new game if progress fetch fails
      }
    } catch (error) {
      console.error("Error fetching game progress:", error);
      getQuestions(); // Start a new game if there's an error fetching progress
    }
  };

  useEffect(() => {
    checkProgress();
  }, [code]);

  useEffect(() => {
    const checkIfInsideWinningCircle = () => {
      if (
        finalPart &&
        position &&
        getDistance(
          position[0],
          position[1],
          finalDestination[0],
          finalDestination[1]
        ) <= 10
      ) {
        console.log("winner");
        const correctAnswers = questions.filter((q) => q.correct).length;
        const endTime = new Date();
        const duration = (endTime - startTime) / 60000;
        const currentTime = new Date().toISOString();
        setCompletionTime(duration.toFixed(2));
        setShowWinner(true);
      }
    };

    checkIfInsideWinningCircle();
  }, [position, questions, finalPart, startTime]);

  useEffect(() => {
    const answeredCount = questions.filter(
      (question) => question.answered
    ).length;
    setQuestionsAnswered(answeredCount);
  }, [questions]);

  useEffect(() => {
    const onPositionUpdate = (pos) => {
      const { latitude, longitude } = pos.coords;
      setPosition([latitude, longitude]);
    };

    const onPositionError = (error) => {
      console.error("Error getting position:", error);
    };

    const watcherId = navigator.geolocation.watchPosition(
      onPositionUpdate,
      onPositionError,
      { enableHighAccuracy: true }
    );

    return () => navigator.geolocation.clearWatch(watcherId);
  }, []);

  useEffect(() => {
    const checkProximity = () => {
      questions.forEach((question) => {
        if (!question.answered && question.visible) {
          const distance = getDistance(
            position[0],
            position[1],
            question.lat,
            question.lang
          );
          if (distance < 10) {
            setActiveQuestion(question);
          }
        }
      });
    };

    if (position) {
      checkProximity();
    }
  }, [position, questions]);

  useEffect(() => {
    const updateCheckup = async () => {
      try {
        await fetch(
          `https://escapecitygames.pellenilsen.no/api/update-checkup/${code}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          }
        );
      } catch (error) {
        console.error("Error updating checkup:", error);
      }
    };

    updateCheckup();

    const intervalId = setInterval(updateCheckup, 110000);

    return () => clearInterval(intervalId);
  }, [code]);

  const handleSubmitSecretWord = () => {
    if (userInput.trim().toLowerCase() === secretWord.toLowerCase()) {
      setBackpackVisible(false);
      setResult("correct");
    } else {
      setBackpackVisible(false);
      setResult("incorrect");
    }
  };

  // const handleGameStart = () => {
  //     if (questions.length === 0) {
  //         getQuestions();
  //     }
  //     const now = new Date().toISOString();
  //     setDateUsed(now);
  //     setIntro(true);
  // };

  const handleGameStart = async () => {
    console.log("GAME START CLICKED");
    try {
      console.log("TRYING");
      const response = await fetch(
        `https://escapecitygames.pellenilsen.no/api/check-code/${code}`
      );
      console.log("FETCHED");
      const data = await response.json();
      console.log("PARSED RESPONSE");
      if (response.ok && data.success) {
        console.log("RESPONSE WAS OK");
        console.log("Date used from API: " + data.date_used);
        const now = new Date();
        let gameStartTime;

        if (!data.date_used && data.date_used !== "0000-00-00 00:00:00") {
          gameStartTime = new Date(data.date_used);
          setIsNewGame(false);
        } else {
          gameStartTime = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setIsNewGame(true);
        }

        setDateUsed(gameStartTime.toISOString());
        setStartTime(now);
        setIntro(true);
        console.log("Game started. Date used:", gameStartTime.toISOString());
      } else {
        console.log("RESPONSE WAS NOT OK");
        console.error("Failed to fetch date used:", data.message);
        const now = new Date().toISOString();
        setDateUsed(now);
        setIntro(true);
      }
      setStartTime(new Date());
    } catch (error) {
      console.error("Error fetching date used:", error);
      const now = new Date().toISOString();
      setDateUsed(now);
      setIntro(true);
    }

    if (questions.length === 0) {
      getQuestions();
    }
  };

  const handleCloseQuiz = () => {
    setActiveQuestion(null);
  };

  const revealLetter = () => {
    if (!secretWord || !secretWordDisplay) {
      console.error("Secret word or display is not set correctly");
      return secretWordDisplay;
    }

    console.log("Secret word before reveal:", secretWord);
    console.log("Secret word display before reveal:", secretWordDisplay);

    const indices = secretWordDisplay.split("").reduce((acc, char, index) => {
      if (char === "_") acc.push(index);
      return acc;
    }, []);

    console.log("Indices of underscores:", indices);
    console.log("Secret word:", secretWord);
    console.log("Secret word length:", secretWord.length);

    if (indices.length > 0) {
      const randomIndex = indices[Math.floor(Math.random() * indices.length)];
      console.log("Random index chosen:", randomIndex);

      const newDisplay = [...secretWordDisplay];
      newDisplay[randomIndex] = secretWord[randomIndex] || "_"; // Safeguard to prevent undefined access

      const updatedDisplay = newDisplay.join("");
      console.log("Secret word display after reveal:", updatedDisplay);
      return updatedDisplay;
    }
    return secretWordDisplay;
  };

  const handleQuizAnswer = (questionId, isCorrect) => {
    setActiveQuestion(null);
    setQuestions((prevQuestions) => {
      let updatedQuestions = prevQuestions.map((question, index) => {
        if (question.id === questionId) {
          return { ...question, answered: true, correct: isCorrect };
        }
        if (index === prevQuestions.findIndex((q) => q.id === questionId) + 1) {
          return { ...question, visible: true };
        }
        return question;
      });
      return updatedQuestions;
    });

    if (isCorrect) {
      const newSecretWordDisplay = revealLetter();
      setSecretWordDisplay(newSecretWordDisplay);
      console.log("New secret word display:", newSecretWordDisplay);
      setNotificationText(
        location.includes("eng")
          ? "Well done! You unlocked a new letter!"
          : "Bra jobba! Dere fikk en ny bokstav!"
      );
      updateProgress(questionId, isCorrect, newSecretWordDisplay);
    } else {
      setNotificationText(
        location.includes("eng")
          ? "Wrong answer. No letter this time."
          : "Feil svar. Ingen bokstav denne gangen."
      );
      updateProgress(questionId, isCorrect, secretWordDisplay);
    }

    setActiveQuestion(null);
  };

  const updateProgress = (questionId, isCorrect, secretWordDisplay) => {
    const result = !!isCorrect;
    const secretWordUpdate = secretWordDisplay;

    fetch(`https://escapecitygames.pellenilsen.no/api/update-progress`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code,
        result,
        secretWord: secretWordUpdate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          console.error("Failed to update progress:", data.message);
        }
      })
      .catch((error) => console.error("Error updating progress:", error));
  };

  const hideAfterQuestionText = () => {
    setAfterQuestionModalText("");
  };

  return (
    <div>
      {!intro ? (
        <Intro location={location} handleGameStart={handleGameStart} />
      ) : (
        dateUsed && (
          <Timer dateUsed={dateUsed} setTimerRanOut={setTimerRanOut} />
        )
      )}
      <Questions
        numberOfQuestions={questions.length}
        numberOfAnswered={questionsAnswered}
        setEndScreen={setEndScreen}
        location={location}
      />
      <Backpack
        secretWordDisplay={secretWordDisplay}
        setNotificationText={setNotificationText}
        endScreen={endScreen}
        secretWord={secretWord}
        handleSubmitSecretWord={handleSubmitSecretWord}
        userInput={userInput}
        setUserInput={setUserInput}
        backpackVisible={backpackVisible}
        setBackpackVisible={setBackpackVisible}
        location={location}
      />
      <Notification
        text={notificationText}
        setNotificationText={setNotificationText}
        setBackpackVisible={setBackpackVisible}
        location={location}
      />
      <AfterQuestionModal
        text={afterQuestionModalText}
        handleClose={hideAfterQuestionText}
        location={location}
      />
      {activeQuestion && (
        <div className="quiz-component z-[999999] fixed top-0 left-0 w-full h-full">
          <QuizCompontent
            title={activeQuestion.title}
            buildup={activeQuestion.buildup}
            question={activeQuestion.question}
            answer={activeQuestion.answer}
            alternatives={activeQuestion.alternatives}
            afterQuestion={activeQuestion.afterQuestion}
            questionId={activeQuestion.id}
            handleQuizAnswer={handleQuizAnswer}
            handleClose={() => {
              setActiveQuestion(null);
              setMapInteractable(true);
            }}
            setAfterQuestionModalText={setAfterQuestionModalText}
          />
        </div>
      )}
      {result == "correct" && (
        <Correct
          setShowFinalDestination={setShowFinalDestination}
          setResult={setResult}
          setFinalPart={setFinalPart}
          location={location}
        />
      )}
      {result == "incorrect" && <Lose />}
      {showWinner && (
        <Winner
          code={code}
          correctAnswers={questions.filter((q) => q.correct).length}
          completionTime={completionTime}
          location={location}
        />
      )}
      {timerRanOut && <TimeLose />}
      <MapContainer
        className={activeQuestion ? "no-pointer-events" : ""}
        center={[59.20298, 10.95741]}
        zoom={17}
        style={{ height: "100vh", width: "100vw" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {questions.map((question) => (
          <>
            <Fragment key={question.id}>
              {question.visible && (
                <>
                  <Marker
                    position={[question.lat, question.lang]}
                    icon={question.answered ? answeredIcon : customIcon}
                  />
                  <Circle
                    center={[question.lat, question.lang]}
                    radius={10}
                    pathOptions={{
                      color: question.answered ? "blue" : "red",
                    }}
                    fillOpacity={0.2}
                  />
                </>
              )}
            </Fragment>
          </>
        ))}
        {showFinalDestination && (
          <>
            <Marker position={[59.201843, 10.95167]} icon={customIcon} />
            <Circle
              center={[59.201843, 10.95167]}
              radius={150}
              pathOptions={{
                color: "orange",
              }}
              fillOpacity={0.2}
            />
          </>
        )}
        {position && (
          <>
            <Marker position={position} icon={userIcon} />
          </>
        )}

        {/* <button
          onClick={debugBtnClick}
          className="fixed bottom-10 right-10 z-50 bg-blue-500 text-white p-3 rounded shadow-lg"
        >
          Move to Next Question (Debug)
        </button> */}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
