import React from "react";

const Correct = ({
  setShowFinalDestination,
  setResult,
  setFinalPart,
  location,
}) => {
  const handleClick = () => {
    setShowFinalDestination(true);
    setResult("");
    setFinalPart(true);
  };

  const isGamlebyen = location === "gamlebyen" || location === "gamlebyen_eng";

  return (
    <div className="fixed overflow-scroll z-[9999] bg-white/90 w-full h-full p-8">
      {isGamlebyen ? (
        <>
          <h1 className="text-2xl font-bold">
            {location.includes("eng") ? "Congratulations!" : "Gratulerer!"}
          </h1>
          <p className="mt-4">
            {location.includes("eng")
              ? "You helped Christian Hals with finding the exit! Now the only thing remaining is looking through the highlighted area, and find the exit to Vaterland!"
              : "Dere har hjulpet Christian Hals med å finne ut av hvor utgangen er! Nå gjenstår det bare å finne den! Let i det markerte område for å finne utgangen til Vaterland!"}
          </p>
          <button
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center"
            onClick={handleClick}
          >
            {location.includes("eng") ? "Start looking!" : "Start letingen!"}
          </button>
        </>
      ) : (
        <>
          <h1 className="text-2xl font-bold">
            {location.includes("eng") ? "Congratulations!" : "Gratulerer!"}
          </h1>
          <p className="mt-4">
            {location.includes("eng")
              ? "You have successfully completed the task!"
              : "Du har fullført oppgaven!"}
          </p>
          <button
            className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center"
            onClick={handleClick}
          >
            {location.includes("eng") ? "Finish game" : "Avslutt spillet"}
          </button>
        </>
      )}
    </div>
  );
};

export default Correct;
