import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AccessPage from "./pages/AccessPage";
import GameMap from "./pages/GameMap";
import Purchase from "./pages/Purchase";
import Navbar from "./components/2024/Navbar";
import TestPurchase from "./pages/TestPurchase";
import Vilkår from "./pages/Vilkår";
import Stats from "./pages/Stats";
import NewHome2024 from "./pages/2024/NewHome2024";
import Footer from "./components/2024/Footer";
import Location from "./pages/2024/Location";
import Kontakt from "./pages/2024/Kontakt";
import ScrollToTop from "./components/2024/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/test" element={<AccessPage />} />
        <Route path="/game" element={<GameMap />} />
        <Route path="/purchase" element={<TestPurchase />} />
        <Route path="/" element={<NewHome2024 />} />
        <Route path="/purchases" element={<Purchase />} />
        <Route path="/vilkår" element={<Vilkår />} />
        <Route path="/leaderboard" element={<Stats />} />
        <Route path="/lokasjoner/:id" element={<Location />} />
        <Route path="/kontakt" element={<Kontakt />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
