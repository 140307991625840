import React, { useState } from "react";
import Hero from "../../components/2024/Hero";
import Intro from "../../components/2024/Intro";
import Lokasjoner from "../../components/2024/Lokasjoner";
import Anmeldelser from "../../components/2024/Anmeldelser";
import { useNavigate } from "react-router-dom";

const NewHome2024 = () => {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [overlay, setOverlay] = useState(false);
  const navigate = useNavigate();

  const handleCodeVerification = async () => {
    if (code.length !== 5) {
      setMessage("Vennligst skiv inn en gyldig 5-sifret kode.");
      return;
    }

    try {
      const response = await fetch(
        `https://escapecitygames.pellenilsen.no/api/check-code/${code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);

        const usageResponse = await fetch(
          `https://escapecitygames.pellenilsen.no/api/check-code-usage/${code}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (usageResponse.ok) {
          const usageData = await usageResponse.json();
          if (usageData.success) {
            setMessage(`Welcome, the code belongs to: ${data.customerName}`);
            navigate(`/game?code=${code}&location=${data.location}`);
          } else {
            setMessage(
              "Koden er allerede i bruk. Vennligst vent 2 minutter og prøv igjen."
            );
          }
        } else {
          const usageErrorData = await usageResponse.json();
          setMessage(usageErrorData.message || "Error checking code usage.");
        }
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Error verifying code.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to connect to the server.");
    }
  };

  const handleOverlayClick = () => {
    setOverlay(!overlay);
  };

  return (
    <div className="bg-[#FBFBFB]">
      {overlay && (
        <div className="fixed bg-white/80 z-[999] w-full h-full flex justify-center items-center">
          <div className="bg-white rounded-2xl p-10 relative">
            <button
              aria-label="Close overlay"
              className="absolute top-4 right-4 text-3xl text-red-700"
              onClick={handleOverlayClick}
            >
              X
            </button>
            <h1 className="text-2xl font-semibold font-primary mt-5">
              Skriv inn din kode:
            </h1>
            <p className="text-sm font-primary mt-5">
              Hvis du har mistet tilkobling, vennligst vent 2 minutter, og prøv
              igjen med samme kode.
            </p>
            <input
              type="text"
              className="block p-4 border w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mt-4"
              placeholder="Eks. 12345"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              className="mt-2 w-full px-4 py-3 bg-gradient-to-r from-[#AAC4FC] to-[#98D1AB] text-lg text-white font-button"
              onClick={handleCodeVerification}
            >
              Start spillet!
            </button>
            {message && <p className="mt-4 text-red-500">{message}</p>}
          </div>
        </div>
      )}
      <Hero setOverlay={setOverlay} />
      <Intro />
      <Lokasjoner />
      <Anmeldelser />
    </div>
  );
};

export default NewHome2024;
