import React from 'react';
import { Mail, Phone, MapPin, Clock } from 'lucide-react';

const Kontakt = () => {
  return (
    <div className="bg-[#FBFBFB]">
      {/* Hero Section */}
      <div 
        className="relative h-[calc(100vh-100px)] bg-cover bg-center"
        style={{ backgroundImage: 'url(/assets/placeholderhero.png)' }}
      >
        <div className="absolute inset-0 bg-black/50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-center">
            Kontakt oss
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-center max-w-3xl px-4">
            Vi er her for å hjelpe deg med spørsmål om spillet eller bestillinger
          </p>
        </div>
      </div>

      {/* Contact Information Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">
            Ta kontakt
          </h2>
          <div className="w-24 h-1 bg-black mb-8"></div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Contact Info */}
            <div className="space-y-8">
              <div>
                <h3 className="text-2xl font-semibold mb-6">Kontaktinformasjon</h3>
                <div className="space-y-4">
                  <div className="flex items-center space-x-4">
                    <Mail className="w-6 h-6 text-blue-600" />
                    <div>
                      <p className="text-sm text-gray-500">E-post</p>
                      <a 
                        href="mailto:support@escapecitygame.no"
                        className="text-lg hover:text-blue-600 transition-colors"
                      >
                        support@escapecitygame.no
                      </a>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <Phone className="w-6 h-6 text-blue-600" />
                    <div>
                      <p className="text-sm text-gray-500">Telefon</p>
                      <a 
                        href="tel:+4795048706"
                        className="text-lg hover:text-blue-600 transition-colors"
                      >
                        +47 950 48 706
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-6">Våre lokasjoner</h3>
                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <MapPin className="w-6 h-6 text-blue-600 mt-1" />
                    <div>
                      <p className="font-semibold text-lg">Gamlebyen Fredrikstad</p>
                      <p className="text-gray-600">Start: Tollboden</p>
                      <p className="text-gray-600">Slutt: Tøyhuset</p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <MapPin className="w-6 h-6 text-blue-600 mt-1" />
                    <div>
                      <p className="font-semibold text-lg">Hamar Sentrum</p>
                      <p className="text-gray-600">Start: Østre Torg</p>
                      <p className="text-gray-600">Slutt: Koigen</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FAQ Section */}
            <div>
              <h3 className="text-2xl font-semibold mb-6">Ofte stilte spørsmål</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="font-semibold text-lg mb-2">Hvor lang tid tar spillet?</h4>
                  <p className="text-gray-600">
                    Spillet tar vanligvis mellom 1 til 2 timer å fullføre, avhengig av gruppens tempo og hvor mange gåter dere løser.
                  </p>
                </div>

                <div>
                  <h4 className="font-semibold text-lg mb-2">Hvor mange kan spille sammen?</h4>
                  <p className="text-gray-600">
                    Vi anbefaler grupper på 2-4 personer. Større grupper kan deles opp og konkurrere mot hverandre.
                  </p>
                </div>

                <div>
                  <h4 className="font-semibold text-lg mb-2">Hva trenger vi for å spille?</h4>
                  <p className="text-gray-600">
                    En smarttelefon med internett-tilkobling per gruppe, komfortable sko, og godt humør!
                  </p>
                </div>

                <div>
                  <h4 className="font-semibold text-lg mb-2">Kan vi spille når som helst?</h4>
                  <p className="text-gray-600">
                    Ja, spillet er tilgjengelig hele døgnet. Vi anbefaler å spille i dagslys for den beste opplevelsen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Kontakt;