import React from 'react';

const Intro = () => {
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      {/* Header */}
      <div className="mb-12">
        <h2 className="text-4xl font-bold text-gray-900 mb-2">
          Prøv spillet i dag
        </h2>
        <div className="w-24 h-1 bg-black mb-4"></div>
        <p className="text-xl text-gray-600">
          Vi har escape room opplevelser flere steder i Norge
        </p>
      </div>

      {/* Grid Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Image - Only visible on desktop */}
        <div className="hidden lg:block lg:row-span-2">
          <img
            src="/assets/statue.jpg"
            alt="Escape Room Experience"
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Info Cards */}
        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">
            Hva er Escape City Game?
          </h3>
          <p className="text-gray-600">
            EscapeCityGame er et utendørs nettspill som går ut på å løse oppgaver og koder gjennom spillets historie. Hvert spill har forskjellige historier og temaer. For å løse oppgavene trengs øynene, hendene og hodet. Lykke til!
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">
            Hvor mange kan spille?
          </h3>
          <p className="text-gray-600">
            Vi anbefaler maks 4 personer per enhet. Er det flere enn 4 personer så anbefaler vi å spille på flere enheter. For eksempel del opp i lag og spill mot hverandre, og klatre ledertavlene våre!
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">
            Er det vanskelig?
          </h3>
          <p className="text-gray-600">
            Spillene har forskjellige vansklighetsgrader. Passer alt fra barnefamilier til de lokale.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">
            Hvor lenge varer spillet?
          </h3>
          <p className="text-gray-600">
            Alle spillene har en makstid på 2 timer. Alle spillene skal fint gå an og gjennomføre innen tiden, og spillene kan spilles når som helst.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Intro;