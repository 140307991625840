import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, Phone, Facebook, Instagram, Copyright } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-[#2b2e37] text-white pt-16 pb-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {/* Left Column - Logo and Contact */}
          <div>
            <div className="flex items-center mb-4">
              <img
                src="/assets/logopng.png"
                alt="Escape City Game Logo"
                className="h-10 w-auto mr-3"
              />
              <span className="text-xl font-bold text-white">
                Escape City Game
              </span>
            </div>
            <div className="w-16 h-0.5 bg-gray-500 mb-4"></div>
            <div className="space-y-2">
              <div className="flex items-center text-white">
                <Mail size={18} className="mr-2" />
                <a 
                  href="mailto:support@escapecitygame.no"
                  className="hover:text-blue-300 transition-colors"
                >
                  support@escapecitygame.no
                </a>
              </div>
              <div className="flex items-center text-white">
                <Phone size={18} className="mr-2" />
                <a 
                  href="tel:+4795048706"
                  className="hover:text-blue-300 transition-colors"
                >
                  +47 950 48 706
                </a>
              </div>
            </div>
          </div>

          {/* Middle Column - Links */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Lenker</h3>
            <div className="w-16 h-0.5 bg-gray-500 mb-4"></div>
            <nav className="space-y-2">
              <Link 
                to="/"
                className="block text-white hover:text-blue-300 transition-colors"
              >
                Hjem
              </Link>
              {/* <Link 
                to="/lokasjoner"
                className="block text-white hover:text-blue-300 transition-colors"
              >
                Lokasjoner
              </Link> */}
              <Link 
                to="/kontakt"
                className="block text-white hover:text-blue-300 transition-colors"
              >
                Kontakt oss
              </Link>
            </nav>
          </div>

          {/* Right Column - Social Media */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Følg oss</h3>
            <div className="w-16 h-0.5 bg-gray-500 mb-4"></div>
            <div className="flex space-x-4">
              <a
                href="https://www.tripadvisor.com.sg/Attraction_Review-g658442-d27966318-Reviews-Escape_City_Game_Gamlebyen_Fredrikstad-Fredrikstad_Fredrikstad_Municipality_Ostf.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-300 transition-colors"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M12.006 4.295c-2.67 0-4.849 2.179-4.849 4.849 0 2.67 2.179 4.849 4.849 4.849s4.849-2.179 4.849-4.849c0-2.67-2.179-4.849-4.849-4.849zm0 7.738c-1.588 0-2.889-1.301-2.889-2.889s1.301-2.889 2.889-2.889 2.889 1.301 2.889 2.889-1.301 2.889-2.889 2.889z"/>
                  <path d="M12.006 0C5.378 0 0 5.378 0 12.006s5.378 12.006 12.006 12.006 12.006-5.378 12.006-12.006S18.634 0 12.006 0zm0 22.261c-5.664 0-10.255-4.591-10.255-10.255S6.342 1.751 12.006 1.751s10.255 4.591 10.255 10.255-4.591 10.255-10.255 10.255z"/>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/escapecitygame/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-300 transition-colors"
              >
                <Instagram size={24} />
              </a>
              <a
                href="https://www.facebook.com/escapecitygame"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-300 transition-colors"
              >
                <Facebook size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Copyright */}
        <div className="border-t border-gray-600 pt-8">
          <p className="text-center text-gray-300 text-sm flex items-center justify-center">
            <Copyright size={16} className="mr-1" />
            2024 Escape City Game. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;