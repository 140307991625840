import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { MapPin, Users, Route, ArrowRight } from 'lucide-react';

const locationData = {
  'gamlebyen': {
    title: 'Gamlebyen Fredrikstad',
    subtitle: 'Opplev historien på en helt ny måte',
    heroImage: '/assets/gamlebyen.png',
    distance: '6km',
    type: 'Familie',
    route: 'Tollboden → Tøyhuset',
    description: `Utforsk Nordens best bevarte festningsby gjennom et spennende byspill! 
    I Gamlebyen Fredrikstad vil du og ditt lag løse gåter og oppgaver mens dere lærer om byens 
    historie og kultur. Spillet tar deg med på en reise fra Tollboden til Tøyhuset, 
    hvor hver oppgave avslører nye hemmeligheter om festningsbyen.`,
    locationImage: '/assets/gamlebyen.png'
  },
  'hamar': {
    title: 'Hamar Sentrum',
    subtitle: 'Opplev Innlandets perle',
    heroImage: '/assets/hamar.png',
    distance: '4km',
    type: 'Familie',
    route: 'Østre Torg → Koigen',
    description: `Bli med på et spennende eventyr gjennom Hamar sentrum! 
    Fra det historiske Østre Torg til den vakre Koigen ved Mjøsa, vil du og ditt lag 
    utforske byens skjulte perler og løse morsomme oppgaver langs veien. 
    Perfekt for både lokale og tilreisende som ønsker å oppleve Hamar på en ny måte.`,
    locationImage: '/assets/hamar.png'
  }
};

const Location = () => {
  const { id } = useParams(); // Get the location ID from URL
  const location = locationData[id] || locationData.gamlebyen; // Default to Gamlebyen if no ID match

  return (
    <div className="bg-[#FBFBFB]">
      {/* Hero Section */}
      <div 
        className="relative h-[calc(100vh-100px)] bg-cover bg-center"
        style={{ backgroundImage: `url(${location.heroImage})` }}
      >
        <div className="absolute inset-0 bg-black/50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-center">
            {location.title}
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-center">
            {location.subtitle}
          </p>
          <div className="flex flex-col items-center space-y-4">
          <Link
            to="/purchase"
            className="bg-blue-600 text-white px-8 py-3 rounded-md text-lg font-medium hover:bg-blue-700 transition-colors duration-200"
          >
            Kjøp spill
          </Link>
          
          <Link
            to="/"
            className="text-white flex items-center hover:text-blue-300 transition-colors duration-200"
          >
            <span className="mr-2 underline">Har allerede kode</span>
            <ArrowRight size={20} />
          </Link>
        </div>
        </div>
      </div>

      {/* Location Details Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">
            {location.title}
          </h2>
          <div className="w-24 h-1 bg-black mb-8"></div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="flex items-center space-x-3">
              <MapPin className="w-6 h-6 text-blue-600" />
              <div>
                <p className="text-sm text-gray-500">Distanse</p>
                <p className="text-lg font-semibold">{location.distance}</p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <Users className="w-6 h-6 text-blue-600" />
              <div>
                <p className="text-sm text-gray-500">Målgruppe</p>
                <p className="text-lg font-semibold">{location.type}</p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <Route className="w-6 h-6 text-blue-600" />
              <div>
                <p className="text-sm text-gray-500">Rute</p>
                <p className="text-lg font-semibold">{location.route}</p>
              </div>
            </div>
          </div>

          {/* Description and Image */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <p className="text-gray-600 text-lg leading-relaxed whitespace-pre-line">
                {location.description}
              </p>
            </div>
            <div>
              <img
                src={location.locationImage}
                alt={`${location.title} detaljer`}
                className="rounded-lg shadow-lg w-full h-auto aspect-square object-cover"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Location;